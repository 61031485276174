<template>
    <div>
        <div class="title">
            <app-header :title="title" :isShowBack="true"></app-header>
        </div>
        <div class="box">
            <div class="authorized">
                <div class="choose-city">
                    <div style="width:40px">城市</div>
                    <el-cascader style="width:16%" :options="provinceList" v-model="city" clearable filterable></el-cascader>
                    <div style="margin:0 10px 0 20px">门店/门店负责人/电话</div>
                    <el-input style="width:28%" v-model="keyword" placeholder="根据门店名称、门店负责人、负责人电话搜索" :clearable="true"></el-input>
                    <div style="margin:0 10px 0 20px;width:80px">货柜MAC</div>
                    <el-input style="width:12%" v-model="mac" placeholder="请输入" clearable></el-input>
                    <div style="display: inline-block;margin-left:10px;">
                        <el-button type="primary" size="small" round @click="checkStore()">查询</el-button>
                    </div>
                </div>
                <div>
                    <el-table height="550" row-key="counterId" ref="table" :data="gridData" style="width: 100%"
                     @selection-change="handleSelectionChange" >
                        <el-table-column type="selection" align="center" :reserve-selection="true"/>
                        <el-table-column label="城市" min-width="90" align="center" prop="cityStr" :show-overflow-tooltip="true"/>
                        <el-table-column label="门店" min-width="120" align="center" prop="groupName" :show-overflow-tooltip="true" />
                        <el-table-column label="门店负责人/电话" min-width="120" align="center" prop="chargeName" :show-overflow-tooltip="true" />
                        <el-table-column label="货柜MAC" min-width="120" align="center" prop="mac"/>
                        <el-table-column label="售货柜" min-width="120" align="center" prop="counterName" :show-overflow-tooltip="true" />
                    </el-table>
                </div>
                <div class="bottom">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 50, 100, 200, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                    <div class="choose-all">
                        <!-- <el-checkbox v-model="isAll" @change="chooseAll()">全选</el-checkbox> -->
                        <!-- <div style="margin-left:10px">共{{total}}个货柜，已选{{selectedData.length}}个</div> -->
                    </div>
                    
                </div>
            </div>
            <div style="margin-left: 40px;">
                <div style="margin:40px 0 20px">已选货柜</div>
                <div class="choose-counter">
                    <el-table row-key="counterId" :data="selectedData" style="width: 100%" height="550" border>
                        <el-table-column prop="groupName" label="门店" width="180"></el-table-column>
                        <el-table-column prop="counterName" label="售货柜" width="180"></el-table-column>
                        <el-table-column label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="handleClick(scope.row.counterId)" type="text" size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div style="margin:10px 0 0;font-size: 14px;">已选{{selectedData.length}}个</div>
            </div>
        </div>
        <div class="submit-btn">
            <el-button style="width:200px" type="primary" @click="submit()">提交</el-button>
        </div>
    </div>
</template>
<script>
import cabinetUtil from '../../utils/cabinetUtil.js'
export default {
    data() {
        let _this = this;
        return {
            title: "授权货柜",
            provinceList: [],
            city: '',
            keyword: '',
            mac: '',
            selectedData: [],
            currentPage: 1,
            skip: 1,
            pageSize:10,
            total:0,
            tableData: [{
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
            }, {
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
            }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
            }],
            gridData: [],
            // 记录全选时的数组长度
            allSelectLength: 0,
            // 全选状态
            allSelectFlag: false,
            // 选择时数据
            multipleSelection: [],
            idKey:'counterId',
            cityList: [],
            isAll:false
        };
        
    },
    activated(){
        this.getCityData();
        // this.queryStores();
        this.getCityList();
        if(this.$route.query.type == 'add'){
            this.multipleSelection = [];
            this.selectedData = [];
            this.$refs.table.clearSelection();
        }else{
            if(this.cache.get("selectedData")){
                this.selectedData = this.cache.get("selectedData")
            }else{
                this.getSelected();
            }
        }
        this.currentPage = 1;
        this.pageSize = 10;
        this.total = 0;
        this.skip = 1;
        this.city = '';
        this.mac= '';
        this.keyword = '';
        this.isAll = false
    },
    methods: {
        submit(){
            this.cache.set("selectedData", this.selectedData);
            this.$back();
        },
        // 查询
        checkStore(){
            this.pageSize=10
            this.skip=1
            this.queryStores('',this.keyword,this.city,this.mac)
        },
        // 获取货柜数据
        queryStores(userId, name, city,mac){
            let dto = {
                pageNum: this.skip,
                pageSize: this.pageSize
            }
            dto.mac = mac
            if (name || city) {
                if(name){
                    dto.key = name
                }
                if(city){
                    dto.province = city[0],
                    dto.city = city[1]
                }
            } else {
                if (userId && userId != 0) {
                    dto.userId = userId
                }
            }
            this.post('mall-service/v1/counter-info/get', dto, {
                isUseResponse: true,
            }).then(res => {
                if (res.data.code == 0) {
                    if(res.data.data.data.length > 0){
                        res.data.data.data.forEach(item =>{
                            if(item.chargeName&&item.chargePhone){
                                item.chargeName = item.chargeName + '/' + item.chargePhone
                            }else{
                                item.chargeName = '--'
                            }
                            item.mac = item.mac||'--'
                            item.counterName = item.counterName?item.counterName:'--'
                            item.cityStr = this.getCityName(item.city);
                        })
                    }
                }
                this.gridData = res.data.data.data;
                this.total = res.data.data.total
                this.currentPage = this.skip
                setTimeout(() => {
                    this.setSelectRow();
                }, 20);
            }).catch(err => {

            })
        },
        // 获取已选货柜数据
        getSelected(){
            this.post('mall-service/v1/get-counter-auth?userId=' + this.$route.query.userId, {
                isUseResponse: true,
            }).then(res => {
                this.selectedData = res
            }).catch(err => {

            })
        },
        // 获取城市下拉框
        getCityData() {
            this.get('/landlord-service/base/getTree').then(res => {
                this.provinceList = res;
            })
        },
        // 获取城市列表
        getCityList(name) {
            return new Promise((resolve, reject) => {
                this.post("/landlord-service/base/getCitys").then(res => {
                    res.forEach(item => {
                        if (item.cityCode) {
                            if (item.cityCode.length == 2) {
                                item.cityCode = '0' + item.cityCode
                            } else if (item.cityCode.length == 1) {
                                item.cityCode = '00' + item.cityCode
                            }
                        }
                    })
                    this.cityList = res;
                    this.queryStores()
                    resolve(res);
                });
            })
        },
        // 根据城市编码知道成功名字
        getCityName(val) {
            for (let i = 0; i < this.cityList.length; i++) {
                if (val == this.cityList[i].cityCode) {
                    return this.cityList[i].name
                }
            }
        },
        // 根据已选择货柜数据选中表格
        setSelectRow() {
            if (!this.selectedData || this.selectedData.length <= 0) {
                return;
            }
            // 标识当前行的唯一键的名称
            let idKey = this.idKey;
            let selectAllIds = [];
            let that = this;
            this.selectedData.forEach(row => {
                selectAllIds.push(row[idKey]);
            });
            this.$refs.table.clearSelection();
            for (var i = 0; i < this.gridData.length; i++) {
                if (selectAllIds.indexOf(this.gridData[i][idKey]) >= 0) {
                    this.$refs.table.toggleRowSelection(this.gridData[i], true);
                }
            }
            if(this.multipleSelection.length == this.gridData.length){
                this.isAll = true
            }else{
                this.isAll = false
            }
        },
        // 记忆选择核心方法
        changePageCoreRecordData() {
            // 标识当前行的唯一键的名称
            let idKey = this.idKey;
            let that = this;
            // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
            if (this.selectedData.length <= 0) {
                this.selectedData = this.multipleSelection;
                return;
            }
            // 总选择里面的key集合
            let selectAllIds = [];
            this.selectedData.forEach(row => {
                selectAllIds.push(row[idKey]);
            });
            let selectIds = [];
            // 获取当前页选中的id
            this.multipleSelection.forEach(row => {
                selectIds.push(row[idKey]);
                // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
                if (selectAllIds.indexOf(row[idKey]) < 0) {
                    that.selectedData.push(row);
                }
            });
            if(this.multipleSelection.length == this.gridData.length){
                this.isAll = true
            }else{
                this.isAll = false
            }
            let noSelectIds = [];
            // 得到当前页没有选中的id
            this.gridData.forEach(row => {
                if (selectIds.indexOf(row[idKey]) < 0) {
                    noSelectIds.push(row[idKey]);
                }
            });
            noSelectIds.forEach(id => {
                if (selectAllIds.indexOf(id) >= 0) {
                    for (let i = 0; i < that.selectedData.length; i++) {
                        if (that.selectedData[i][idKey] == id) {
                            // 如果总选择中有未被选中的，那么就删除这条
                            that.selectedData.splice(i, 1);
                            break;
                        }
                    }
                }
            });
        },
        //改变当前页
        handleCurrentChange(val) {
            // 改变页的时候调用一次
            this.skip = val;
            this.queryStores('',this.keyword,this.city,this.mac)
            this.changePageCoreRecordData();
            // this.index();
        },
        //改变每页显示条数
        handleSizeChange(val) {
            // 改变每页显示条数的时候调用一次
            this.pageSize = val;
            this.skip = 1;
			this.currentPage = 1;
            this.queryStores('',this.keyword,this.city,this.mac)
            this.changePageCoreRecordData();
            // this.index();
        },
        //表格多选框选中改变事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
            setTimeout(() => {
                this.getRight();
            }, 50)
        },
        chooseAll(){
            if(this.isAll){
               this.multipleSelection = this.gridData;
            }else{
                this.multipleSelection = []
                this.$refs.table.clearSelection();
            }
            // this.selectedData = this.multipleSelection
            setTimeout(() => {
                this.getRight();
                this.setSelectRow();
            }, 50)
        },
        //必须在此多封装一层调用changePageCoreRecordData，然后给handleSelectionChange中调用，不然有bug
        getRight() {
            this.changePageCoreRecordData();
        },
        // 点击删除已选货柜数据
        handleClick(id) {
            let row = this.gridData.filter(i => {
                return i.counterId === id;
            });
            if (row && row.length) {
                this.$refs.table.toggleRowSelection(row[0]);
            }
            this.selectedData = this.selectedData.filter(i => {
                return i.counterId !== id;
            });
        },
        index() {
            $('#elTable .el-table__body-wrapper').scrollTop(0);
            setTimeout(() => {
                this.setSelectRow();
            }, 20);
        },
    }
}
</script>
<style scoped lang='scss'>
    .title {
        position: relative;
        top: 20px;
        left: 20px;
    }
    .box{
        display: flex;
        justify-content: space-between;
        overflow: auto;
    }
    .authorized{
        padding-left: 22px;
        padding-right: 20px;
        border-right: solid 1px #DCDFE6;
        width: 70%;
    }
    .choose-city{
        display: flex;
        height: 40px;
        line-height: 40px;
        margin: 30px 0;
    }
    .line{
        background: #DCDFE6;
        width: 1px;
    }
    .bottom{
        margin-top:10px;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .choose-all{
        font-size: 14px;
        display:flex;
        align-items:center;
        height: 40px;
        line-height: 40px;
    }
    .submit-btn{
        text-align: center;
        margin-top: 10px;
    }
</style>